.page-header-area {
    width: 100%;
    background-size: cover;
    background-position: center;
    z-index: 2;

    .page-header-area-inner {
        width: 100%;
        text-align: center;
        position: relative;
        padding: 55px 0;
        color: $theme-WhiteColor;
        background-color: rgba(0,0,0,.5);

        .page-header-content {
            h2 {
                @include font-size(40px);
                line-height: 1.25;
                margin-bottom: 5px;
                text-transform: capitalize;
                color: $theme-WhiteColor;
            }

            .breadcrumb-wrapper {
                color: $theme-SkinColor;
                a{
                    color: $theme-WhiteColor;
                }
                .bread-sep {
                    font-size: 12px;
                    margin: 0 4px;
                    color: $theme-WhiteColor;
                }
            }
        }
    }
}