/*  ===============================================
    TopBar
------------------------*/
.top_bar {
	width: 100%;
	z-index: 3;
	.social-icons {
		padding: 0 8px;
		li {
			margin: 0;
			a {
				margin: 0;
				font-size: 13px;
				min-width: 35px;
			}
		}
		&:after {
			position: absolute;
			content: '';
			top: 0;
			height: 100%;
			width: 1px;
			left: 0;
			background-color: rgba(255, 255, 255, 0.08);
			z-index: 1;
		}
	}
}
.top_bar_icon {
	display: inline-block;
	vertical-align: middle;
	margin-right: 8px;
}
.top_bar_contact_item {
	font-size: 13px;
	padding: 10px 0;
	position: relative;
}
.ttm-bgcolor-darkgrey {
	.top_bar_contact_item {
		.top_bar_icon {
			i {
				color: rgba(255,255,255,.37);
			}
		}
		color: rgba(255,255,255,.37);
	}
}


/*  ===============================================
    Header
------------------------*/
.header {
	.site-header-menu {
		.container-fluid { 
			padding: 0 50px;

			@media (max-width: 1499px){
				padding: 0 24px;
			}
		}

	}
	&.is-Sticky {
		.site-header-menu {
			z-index: 2;
			background-color: #fff;
			box-shadow: 0px 13px 25px -12px rgba(0, 0, 0, .15);
			position: fixed !important;
			top: 0;
			left: 0;
			width: 100%;
		}
	}
}

/** SiteBrand(logo) **/
.site-branding {
	img {
		max-height: 39px;

		@media (max-width: 1199px){
			max-height: 32px;
			width: auto;
		}
	}
	display: flex;
	flex-direction: column;
	position: relative;
	justify-content: center;

	@media (max-width: 1199px){
		padding: 15px 0;
	}
}

/* header_extra */
.header_extra {
	>div {
		margin-left: 8px;
		margin-right: 8px;
		position: relative;
	}
}
.header_search_content {
	button.close-search {
		position: absolute;
		right: 15px;
		padding: 0;
		color: #fff;
		font-size: 21px;
		border-radius: 0;
		box-shadow: unset;
	}
}
.header_search {
	
	.header_search_content {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		background: rgba(0,0,0,0.8);
		height: 100%;
		width: 100%;
		z-index: 10;
		-webkit-transition: all 300ms;
		-o-transition: all 300ms;
		-moz-transition: all 300ms;
		transition: all 300ms;
		#searchbox {
			position: absolute;
			left: 0;
			right: 0;
			width: 620px;
			max-width: 100%;
			height: 100%;
			margin: 0 auto;
			display: flex;
			flex-direction: column;
			justify-content: center;

			.search_query {
				background: none;
				border: 0;
				border-bottom: 1px solid rgba(255,255,255,1);
				height: 70px;
				padding: 0 60px 0 15px;
				font-weight: 400;
				font-size: 25px;
				width: 100%;
				color: #fff;
				letter-spacing: 0;
				text-overflow: ellipsis;
				overflow: hidden;
				outline: none;
				background-color: transparent;
			}
			input {
				&::placeholder {
					color: #fff;
				}
			}
		}
		.close_btn {
			i {
				font-size: 13px;
				font-weight: 600;
				color: #fff;
				-o-transition: all 0.3s;
				-moz-transition: all 0.3s;
				transition: all 0.3s;
				height: 35px;
				width: 35px;
				display: block;
				line-height: 35px;
				text-align: center;
				border-radius: 50%;
			}
			&:hover {
				i {
					-webkit-transform: rotate(180deg);
					-moz-transform: rotate(180deg);
					-ms-transform: rotate(180deg);
					-o-transform: rotate(180deg);
					transform: rotate(180deg);
				}
			}
		}
	}
	.header_search_content.on {
		visibility: visible;
		opacity: 1;
		display: block;
		-webkit-transform: translateY(0);
		-moz-transform: translateY(0);
		-ms-transform: translateY(0);
		-o-transform: translateY(0);
		transform: translateY(0);
	}
	.header_search_content_inner {
		.close_btn {
			position: absolute;
			right: 60px;
			left: auto;
			top: 60px;
			color: #fff;
			cursor: pointer;
			text-align: center;
			border-radius: 50%;
			border: 1px solid;
		}
	}
}


@media (max-width: 1199px){ 

	.header_btn, .header_extra  { 
		display: none !important;
	}

}

/** SiteNavigation(Menu) **/
.site-navigation {
	position: relative;
	z-index: 2;
}
nav.main-menu {
	ul {
		position: relative;
		margin: 0;
	}
}
#site-header-menu {
	.site-navigation {
		ul.menu {
			>li {
				>a {
					font-weight: 500;
					font-family: $headingfont;
					display: block;
					text-transform: capitalize;
					font-size: 14px;
					line-height: 1;
				}
			}
		}
	}
}
.header_social {
	margin-left: 20px !important;
	&:before {
		position: absolute;
		content: "";
		height: 100%;
		width: 1px;
		top: 0;
		left: -10px;
		background-color: #e4ebff;
	}

	@media (max-width: 1330px){ 
		display: none;
	}
}


@media only screen and (min-width: 1200px){ 

	/* ttm-header-style 01*/
	.ttm-header-style-01 {
		#site-header-menu {
			.site-navigation {
				.border-box-block {
					padding: 0 30px;
					margin: 0 50px;
					flex: 1 0 auto;
					border: 1px solid #e4ebff;
					border-top: 0;
					border-bottom: 0;
				}
				ul.menu {
					>li {
						>a {
							padding: 33px 20px 33px 20px;
							position: relative;
							&.active {
								color: $theme-SkinColor;
							}
						}
					}
				}
			}
		}
	}

}
