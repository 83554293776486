.footer {
	z-index: 1;
	background-position: center center;
	background-attachment: scroll;
    color: rgba(255, 255, 255, 0.58);
	.footer-logo {
		img {
			max-height: 40px;
		}
	}
	.widget {
		.widget-title {
			font-weight: 700;
			font-size: 18px;
			line-height: 20px;
			margin-bottom: 35px;
			display: inline-block;
			position: relative;

			@media (max-width: 992px){
				margin-top: -8px;
				margin-bottom: 30px;
			}
		}
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
		}
    }
}

/** ttm-recent-post-list **/
.widget-area .widget ul.ttm-recent-post-list {
    >li {
        display: table;
        padding-top: 0;
        padding-bottom: 25px;
        margin-bottom: 25px;
        border-bottom: 1px solid rgba(255,255,255,.08);
        &:last-child {
            padding-bottom: 0;
            margin-bottom: 0;
            border-bottom: 0;
        }
        >.post-detail {
            a {
                font-size: 14px;
                line-height: 1.5;
				padding-bottom: 0;
                display: block;
                font-weight: 400;
                position: relative;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
				
            }
            >.cat-link {
                a {
                    font-size: 13px;
                    line-height: 1;
                    display: inline-block;
                    margin-bottom: 5px;
                    color: $theme-SkinColor;
                    border-bottom: 1px solid;
                    text-transform: uppercase;
                }
            }
        }
    }
}
.footer-logo {
	margin-bottom: 30px;
}

/* second-footer */
.second-footer {
	.container {
		position: relative;
		&:before {
			position: absolute;
			content: "";
			left: 15px;
			right: 15px;
			bottom: 0;
			border-bottom: 1px solid rgba(255,255,255,.06);
		}
	}
	.widget-area {
		.widget {
			padding: 65px 0 65px;

			@media (max-width: 992px){
				padding: 50px 0 0;
			}
		}
		.widget-contact {
			background-color: rgba(255,255,255,.03);
			padding: 30px;
			margin-top: 50px;
			margin-bottom: 50px;
		}
	}
}

/** widget_contact_wrapper **/
ul.widget_contact_wrapper {
	li {
		padding-left: 54px;
		position: relative;
		padding-bottom: 20px;
		margin-bottom: 20px;
		border-bottom: 1px solid rgba(255,255,255,.06);
		&:last-child {
			padding-bottom: 0;
			margin-bottom: 0;
			border-bottom: 0;
		}
		i {
			position: absolute;
			left: 0;
			top: 2px;
			line-height: 1;
			width: 1em;
            color: $theme-SkinColor;
			text-align: center;
			font-size: 33px;
			font-weight: 400;
		}
		h3 {
			font-size: 15px;
			line-height: 1;
			margin-bottom: 5px;
			font-weight: 400;
		}
	}
}

/** Footer-nav-menu **/
.footer-nav-menu {
	list-style: none;
	margin: 0;
	padding: 0;
	li {
		display: inline-block;
		padding-left: 2px;
		padding-right: 16px;
		font-size: 13px;
		font-weight: normal;
		padding-bottom: 0;
		
        a {
            color: #cbcbcb !important;
            &:hover {
                color: $theme-SkinColor !important;
            }
        }
        
		&:last-child {
			padding-right: 0;
		}
		&:first-child {
			padding-left: 0;
		}
	}
}
ul.footer-nav-menu {
	li {
		&:not(:last-child) {
			&:after {
				position: absolute;
				content: "|";
				color: #fff;
				padding-left: 7px;
			}
		}
	}
}
.bottom-footer-text {
	padding-top: 30px;
	padding-bottom: 30px;
	background-color: transparent;
	background-position: center center;
	background-size: auto;
	background-repeat: no-repeat;
	background-attachment: fixed;
}

/** copyright **/
.copyright {
	display: block;
	font-size: 13px;
	line-height: 1;
	margin-bottom: 5px;
	color: #cbcbcb !important;
}
.subscribe-form {
	padding-bottom: 20px;
	width: 100%;
	position: relative;
	border-bottom: 1px solid rgba(255, 255, 255, 0.08);

	@media (max-width: 992px){
		margin-top: 15px;
	}

	input[type="email"] {
		background-color: transparent;
		padding: 0;
		border: 0;
        color: $theme-WhiteColor;
		padding-right: 40px;
		box-shadow: unset;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		width: 100%;
	
        &::placeholder  {
            color: #a3a3a3;
        }
    }
	button.submit {
		padding: 0;
		border: 0;
		position: absolute;
		right: 0;
		top: -2px;

        i {
            font-size: 25px;
            line-height: 1;
        }
	}
}
