// Padding
@for $i from 0 through 40 {
  .p-#{$i * 5} {
    padding: $i * 5px !important;
  }
}
// Padding Responsive
@for $i from 0 through 40 {
  .p-md-#{$i * 5} {
    @media only screen and (max-width: 767px) {
      padding: $i * 5px !important;
    }
  }
}
@for $i from 0 through 40 {
  .p-lg-#{$i * 5} {
    @media only screen and (max-width: 991px) {
      padding: $i * 5px !important;
    }
  }
}

// Padding Top
@for $i from 0 through 40 {
  .pt-#{$i * 5} {
    padding-top: $i * 5px !important;
  }
}
// Padding Top Responsive
@for $i from 0 through 40 {
  .pt-md-#{$i * 5} {
    @media only screen and (max-width: 767px) {
      padding-top: $i * 5px !important;
    }
  }
}
@for $i from 0 through 40 {
  .pt-lg-#{$i * 5} {
    @media only screen and (max-width: 991px) {
      padding-top: $i * 5px !important;
    }
  }
}

// Padding Bottom
@for $i from 0 through 40 {
  .pb-#{$i * 5} {
    padding-bottom: $i * 5px !important;
  }
}
// Padding Bottom Responsive
@for $i from 0 through 40 {
  .pb-md-#{$i * 5} {
    @media only screen and (max-width: 767px) {
      padding-bottom: $i * 5px !important;
    }
  }
}
@for $i from 0 through 40 {
  .pb-lg-#{$i * 5} {
    @media only screen and (max-width: 991px) {
      padding-bottom: $i * 5px !important;
    }
  }
}

// Padding Left
@for $i from 0 through 40 {
  .pl-#{$i * 5} {
    padding-left: $i * 5px !important;
  }
}
// Padding Left Responsive
@for $i from 0 through 40 {
  .pl-md-#{$i * 5} {
    @media only screen and (max-width: 767px) {
      padding-left: $i * 5px !important;
    }
  }
}
@for $i from 0 through 40 {
  .pl-lg-#{$i * 5} {
    @media only screen and (max-width: 991px) {
      padding-left: $i * 5px !important;
    }
  }
}

// Padding Right
@for $i from 0 through 40 {
  .pr-#{$i * 5} {
    padding-right: $i * 5px !important;
  }
}
// Padding Right Responsive
@for $i from 0 through 40 {
  .pr-md-#{$i * 5} {
    @media only screen and (max-width: 767px) {
      padding-right: $i * 5px !important;
    }
  }
}
@for $i from 0 through 40 {
  .pr-lg-#{$i * 5} {
    @media only screen and (max-width: 991px) {
      padding-right: $i * 5px !important;
    }
  }
}

// Margin

// Margin Top Minus
@for $i from 0 through 40 {
  .mt_#{$i * 5} {
    margin-top: $i * -5px !important;
  }
}

// Margin Top Minus Responsive
@for $i from 0 through 40 {
  .mt_md-#{$i * 5} {
    @media only screen and (max-width: 767px) {
      margin-top: $i * -5px !important;
    }
  }
}
@for $i from 0 through 40 {
  .mt_lg-#{$i * 5} {
    @media only screen and (max-width: 991px) {
      margin-top: $i * -5px !important;
    }
  }
}


// Margin Top
@for $i from 0 through 40 {
  .mt-#{$i * 5} {
    margin-top: $i * 5px !important;
  }
}

// Margin Top Responsive
@for $i from 0 through 40 {
  .mt-md-#{$i * 5} {
    @media only screen and (max-width: 767px) {
      margin-top: $i * 5px !important; 
    }
  }
}
@for $i from 0 through 40 {
  .mt-lg-#{$i * 5} {
    @media only screen and (max-width: 991px) {
      margin-top: $i * 5px !important;
    }
  }
}

// Margin bottom Minus
@for $i from 0 through 40 {
  .mb_#{$i * 5} {
    margin-bottom: $i * -5px !important;
  }
}

// Margin bottom Minus Responsive
@for $i from 0 through 40 {
  .mb_md-#{$i * 5} {
    @media only screen and (max-width: 767px) {
      margin-bottom: $i * -5px !important;
    }
  }
}
@for $i from 0 through 40 {
  .mb_lg-#{$i * 5} {
    @media only screen and (max-width: 991px) {
      margin-bottom: $i * -5px !important;
    }
  }
}

// Margin Bottom
@for $i from 0 through 40 {
  .mb-#{$i * 5} {
    margin-bottom: $i * 5px !important;
  }
}

// Margin Bottom Responsive
@for $i from 0 through 40 {
  .mb-md-#{$i * 5} {
    @media only screen and (max-width: 767px) {
      margin-bottom: $i * 5px !important;
    }
  }
}
@for $i from 0 through 40 {
  .mb-lg-#{$i * 5} {
    @media only screen and (max-width: 991px) {
      margin-bottom: $i * 5px !important;
    }
  }
}



// Margin left Minus
@for $i from 0 through 40 {
  .ml_#{$i * 5} {
    margin-left: $i * -5px !important;
  }
}

// Margin left Minus Responsive
@for $i from 0 through 40 {
  .ml_md-#{$i * 5} {
    @media only screen and (max-width: 767px) {
      margin-left: $i * -5px !important;
    }
  }
}
@for $i from 0 through 40 {
  .ml_lg-#{$i * 5} {
    @media only screen and (max-width: 991px) {
      margin-left: $i * -5px !important;
    }
  }
}


// Margin left
@for $i from 0 through 40 {
  .ml-#{$i * 5} {
    margin-left: $i * 5px !important;
  }
}

// Margin left Responsive
@for $i from 0 through 40 {
  .ml-md-#{$i * 5} {
    @media only screen and (max-width: 767px) {
      margin-left: $i * 5px !important;
    }
  }
}
@for $i from 0 through 40 {
  .ml-lg-#{$i * 5} {
    @media only screen and (max-width: 991px) {
      margin-left: $i * 5px !important;
    }
  }
}


// Margin right Minus
@for $i from 0 through 40 {
  .mr_#{$i * 5} {
    margin-right: $i * -5px !important;
  }
}

// Margin right Minus Responsive
@for $i from 0 through 40 {
  .mr_md-#{$i * 5} {
    @media only screen and (max-width: 767px) {
      margin-right: $i * -5px !important;
    }
  }
}
@for $i from 0 through 40 {
  .mr_lg-#{$i * 5} {
    @media only screen and (max-width: 991px) {
      margin-right: $i * -5px !important;
    }
  }
}

// Margin Right
@for $i from 0 through 40 {
  .mr-#{$i * 5} {
    margin-right: $i * 5px !important;
  }
}

// Margin Right Responsive
@for $i from 0 through 40 {
  .mr-md-#{$i * 5} {
    @media only screen and (max-width: 767px) {
      margin-right: $i * 5px !important;
    }
  }
}
@for $i from 0 through 40 {
  .mr-lg-#{$i * 5} {
    @media only screen and (max-width: 991px) {
      margin-right: $i * 5px !important;
    }
  }
}

.spacing-1 {
  padding: 70px 0 0 70px;
  margin-left: -180px;
  margin-top: 70px;

  @media (max-width: 991px){
      padding: 60px 24px;
      margin: 0;
  }
}

.spacing-2 {
    padding: 105px 0 245px;
    margin-top: -125px;
    margin-left: 230px;
    margin-right: -230px;
    margin-bottom: -230px;

    @media (max-width: 991px){
      margin-top: 0px;
      margin-right: -60px;
      margin-left: 60px;
      padding-top: 0px;
      margin-bottom: -150px;
    }
}

.spacing-3 {
    padding: 91px 200px 58px 15px;
    margin-top: -70px;

    @media (max-width: 991px){
      margin-top: 0px;
      padding: 60px 15px;
    }
}