.error-pg {
    margin: auto;
    text-align: center;
    height: 100vh;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media only screen and (max-width: 991px) { 
        text-align: center;        
    }

    .page-header {
        .page-title {
            @include font-size(90px);
            line-height: 1;
            font-family: $bodyfont;
        }
    }
    .page-content {
        p{
            @include font-size(60px);
            line-height: 1;
            font-family: $bodyfont;
            color: $theme-DarkColor;
            font-weight: 600;
            margin-bottom: 50px;
        }
    }
    .error-type {
        text-align: center;
        margin-bottom: 40px;
        display: inline-flex;
        h2 {
            @include font-size(300px);
            line-height: 1;
            margin: 0 -20px;
            @media only screen and (min-width: 1200px) {
                margin: 0;
            }
        }
        .error-icon {
            display: inline-block;
            vertical-align: middle;
            position: relative;
            padding: 20px 20px;
            cursor: pointer;
            &:after {
                position: absolute;
                content: "";
                bottom: 0px;
                left: 0;
                right: 0;
                background-color: #f3f3f4;
                height: 44px;
                z-index: -1;
                border-radius: 50%;
            }
            img {
                transition: all 0.5s ease-in-out 0s;
                animation-duration: 2s;
                animation-iteration-count: infinite;
                transform-origin: bottom;
            }
        }
    }
}

// key frames
.bounce {
    animation-name: bounce;
    animation-duration: 4s;
    -moz-animation-duration: 4s;
}
@keyframes bounce {
    0%   { transform: translateY(0); }
    50%  { transform: translateY(-5px); }
    100% { transform: translateY(0); }
}